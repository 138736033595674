import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';

import Home from './Home.js';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' component={ Home } exact />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
