import React from 'react';

import Step from './Step';

import '../styles/components/Body.css';

const ingredientsRice = [{
  item: 'White rice',
  amount: '3 cups'
}, {
  item: 'Olive oil',
  amount: '3 teaspoons'
}, {
  item: 'Sale',
  amount: '1.5 teaspoons'
}];

const instructionsRice = [
  'Add rice to the rice cooker',
  'Add water to the appropriate level',
  'Mix in the rest of the above ingredients',
  'Start cooker'
];

const ingredientsSofrito = [{
  item: 'Sweet yellow onions',
  amount: '1.5 pounds, or 2 of small-medium size'
}, {
  item: 'Garlic',
  amount: '1 pound'
}, {
  item: 'Sweet bell peppers',
  amount: '1 pound'
}, {
  item: 'Cilantro',
  amount: '1.5 bunches'
}];

const instructionsSofrito = [
  'Coarsely dice the onions',
  'Peel the garlic cloves',
  'Remove head and seeds from the peppers and roughly chop',
  'Chop cilantro at the stems (leaving some stems from the leafy area)',
  'Add all ingredients to blender and finely blend'
];

const ingredientsBeans = [{
  item: 'Cooked black beans',
  amount: '2 cans'
}, {
  item: 'Sofrito (from Step 2)',
  amount: '2 tablespoons'
}, {
  item: 'Olive oil',
  amount: '1 quarter cup'
}, {
  item: 'Apple cider vinegar',
  amount: '1 quarter cup'
}, {
  item: 'Chicken bouillon',
  amount: '1 cube'
}, {
  item: 'Bay leaves',
  amount: '2 leaves'
}];

const instructionsBeans = [
  'Coarsely dice the onions',
  'Peel the garlic cloves',
  'Remove head and seeds from the peppers and roughly chop',
  'Chop cilantro at the stems (leaving some stems from the leafy area)',
  'Add all ingredients to blender and finely blend'
];

const stepRice = (
  <Step
    classes='u-marginBottom110'
    header='Step 1: Prepare the rice (rice cooker)'
    ingredients={ ingredientsRice }
    instructions={ instructionsRice }
  />
);

const stepSofrito = (
  <Step
    classes='u-marginTop60'
    header='Step 2: Prepare the sofrito'
    ingredients={ ingredientsSofrito }
    instructions={ instructionsSofrito }
  />
);

const stepBeans = (
  <Step
    header='Step 3: Prepare the black beans'
    ingredients={ ingredientsBeans }
    instructions={ instructionsBeans }
  />
);

const steps = [stepRice, stepSofrito, stepBeans];

const Body = () => {
  if (true) {
    return null;
  }

  return (
    <div className='Body'>
      <h1 className='Body-header'>Borinquen Black Beans and Rice</h1>
      <div className='Body-body'>
        { steps }
      </div>
    </div>
  );
};

export default Body;
