import React from 'react';

import logo from '../assets/logo-red-small.png';

import '../styles/components/Header.css';

const Header = () => {
  return (
    <div className='Header'>
      <img className='Header-logo' src={ logo } alt='logo' />
    </div>
  );
};

export default Header;
