import React from 'react';

import Body from '../components/Body';
import Header from '../components/Header';

const Home = () => {
  return (
    <div className='Home'>
      <Header />
      <Body />
    </div>
  );
};

export default Home;
