import PropTypes from 'prop-types';
import React from 'react';

import '../styles/components/Step.css';

const Step = (props) => {
  const { classes, header, ingredients, instructions } = props;

  return (
    <div className={ `Step ${classes}` }>
      <div className='Step-header'>
        <span className='Step-header-text'>{ header }</span>
      </div>
      <div className='Step-body'>
        <div>
          <span>Ingredients:</span>
          <ul>
            {
              ingredients.map(({ amount, item }) => (
                <li className='Step-body-li'>
                  { item } -
                  <span className='Step-body-li-amount'>{ amount }</span>
                </li>
              ))
            }
          </ul>
        </div>
        <div>
          <span>Instructions:</span>
          <ol>
            {
              instructions.map(instruction => (
                <li className='Step-body-li'>{ instruction }</li>
              ))
            }
          </ol>
        </div>
      </div>
      <div className='Step-footer'>
      </div>
    </div>
  );
};

Step.propTypes = {
  classes: PropTypes.string,
  header: PropTypes.string.isRequired,
  ingredients: PropTypes.array.isRequired,
  instructions: PropTypes.array.isRequired
};

Step.defaultProps = {
  classes: ''
};

export default Step;
